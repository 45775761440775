.App {
  font-family: "Josefin Sans";
  background: #f3f2ed;
  min-height: 100vh;
}

.container {
  padding: 0 2.5vw 2.5vw 2.5vw;
  box-sizing: border-box;
}

h1 {
  margin: 0;
  font-size: 40px;
  color: #615e5a;
  line-height: 27px;
}

a {
  text-decoration: none;
  color: #9c9c9c;
}

.nav-container {
  padding: 5vh 2.5vw 5vh 2.5vw;
  box-sizing: border-box;
}

.nav-top-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.nav-top-container a {
  margin-left: 20px;
  transition: all 0.2s ease;
}

.nav-top-container a:hover {
  transform: scale(110%);
}

.nav-bottom-container {
  color: #9c9c9c;
  font-size: 14px;
  font-style: italic;
}

.mobile-nav-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mobile-nav-container h1 {
  text-align: center;
  line-height: 1;
}

.mobile-nav-top-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 30px;
}

.mobile-nav-bottom-container {
  margin-top: 20px;
}

.nav-link-container {
  margin-top: 20px;
}

.nav-link-container a {
  padding-left: 10px;
  padding-right: 10px;
}

.home-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.image-parent {
  min-height: 500px;
  max-height: 100%;
  width: auto;
  margin: 0.3%;
  position: relative;
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.Small {
  max-width: 32.72%;
}

.Wide {
  min-width: 45%;
  max-width: 66%;
  flex: 1 1;
}

.image-overlay-container {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.image-overlay {
  display: block;
  background-color: black;
  opacity: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
}

.image-title {
  position: absolute;
  margin-top: 300px;
  top: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  color: white;
  font-size: 30px;
  transition: 0.5s ease;
}

.image-overlay-container:hover .image-title {
  opacity: 1;
}

.image-overlay:hover {
  opacity: 0.6;
}

.lightbox-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #00000099;
  z-index: 999;
  width: 100vw;
  height: 100%;
  background: "green";
}

.lightbox-image {
  width: 90%;
  object-fit: contain;
  height: 90%;
}

.about-container {
  display: flex;
  flex-direction: row;
}

.about-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about {
  width: 60%;
  height: 80vh;
}

.about img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: 0 15%;
  justify-self: center;
  align-self: center;
  border: solid 2px #615e5a;
}

.about-text-container {
  width: auto;
  height: auto;
}

.about-container-title {
  font-size: 30px;
  text-align: center;
}

.about-container-description {
  line-height: 2.2;
}

.about-container-social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resume-icon {
  background: #615e5a;
  padding: 12px;
  border-radius: 5px;
  color: white;
  margin-left: 5px;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 80vh;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 80%;
  box-sizing: border-box;
  padding: 20px;
  background: #615e5a;
  border-radius: 10px;
  color: white;
}

.contact-container p {
  width: 90%;
  font-size: 30px;
}

.contact-container form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  align-items: center;
}

.contact-container form button {
  margin-top: 20px;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
  background: white;
  font-size: 20px;
  font-weight: 600;
  color: #615e5a;
  width: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.contact-container form button:hover {
  transform: scale(110%);
}

.contact-input {
  height: 7%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.contact-container form label input {
  height: 100%;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
}

.contact-textarea {
  height: 30%;
  margin-top: 10px;
  width: 100%;
}

.contact-textarea textarea {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-top: 20px;
  box-sizing: border-box;
}

.active-link {
  color: #615e5a;
  transform: scale(110%);
}

.loader {
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.loader-text {
  font-size: 16px;
  color: #7fa878;
  padding: 10px;
  font-weight: 500;
  text-align: center;
}
.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #99c991;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #99c991;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 800px) {
  .home-container {
    flex-direction: column;
  }
  
  .image-parent {
    min-height: auto;
    max-width: 100%;
    height: auto;
  }

  .image-parent img {
    height: 100%;
    object-fit: contain;
  }

  .about-container {
    flex-direction: column;
  }

  .about-item {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .contact-input {
    height: 40px;
  }

  .contact-textarea {
    height: 200px;
  }

  .contact-container {
    margin-bottom: 20px;
  }
}
